<template>


    <div id="page-user-list">
      <div class="vx-card p-6">
        <div class="vx-card__header">
          <div class="vx-card__title">
          </div>
        </div>
        <div class="flex flex-wrap items-center">
  
          <!-- ITEMS PER PAGE -->
          <div class="flex-grow">
            <div class="vx-card__title">
              <h4>รายชื่อผู้ส่งรูปทำกิจกรรม</h4>         
              <br>
            </div>
        <!-- TEST -->
            
     <br>
      <div>
        <vx-card >
      <vs-table stripe  max-items="20" pagination :data="memberData" search>
      <template slot="thead">
            <vs-th>Datetime</vs-th>
            <vs-th>ยูสเซอร์เนม</vs-th>
            
            <vs-th></vs-th>
            <!-- <vs-th>Password</vs-th> -->
      </template>
          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                <vs-td :data="tr.timestamp">
              {{moment(tr.timestamp).format('YYYY-MM-DD HH:mm:ss')  }}
            </vs-td>
            
            <vs-td class="text-danger"><router-link :to="`/member/${data[indextr].username}`">{{ data[indextr].username}}</router-link></vs-td>
              <vs-button class="mx-1" size="small" color="rgb(115, 103, 240)" type="filled" target :href="{url: `${url}prediction/fifaworldcup/${data[indextr].username}_1.jpg`}">ดูรูปภาพ1</vs-button>
              <vs-button class="mx-1" size="small" color="rgb(115, 103, 240)" type="filled" target :href="{url: `${url}prediction/fifaworldcup/${data[indextr].username}_2.jpg`}">ดูรูปภาพ2</vs-button>
              <vs-button class="mx-1" size="small" color="rgb(115, 103, 240)" type="filled" target :href="{url: `${url}prediction/fifaworldcup/${data[indextr].username}_3.jpg`}">ดูรูปภาพ3</vs-button>
              <vs-button class="mx-1" size="small" color="rgb(115, 103, 240)" type="filled" target :href="{url: `${url}prediction/fifaworldcup/${data[indextr].username}_4.jpg`}">ดูรูปภาพ4</vs-button>
              <vs-button class="mx-1" size="small" color="rgb(115, 103, 240)" type="filled" target :href="{url: `${url}prediction/fifaworldcup/${data[indextr].username}_5.jpg`}">ดูรูปภาพ5</vs-button>
              <vs-button class="mx-1" size="small" color="rgb(115, 103, 240)" type="filled" target :href="{url: `${url}prediction/fifaworldcup/${data[indextr].username}_6.jpg`}">ดูรูปภาพ6</vs-button>
              <vs-button class="mx-1" size="small" color="rgb(115, 103, 240)" type="filled" target :href="{url: `${url}prediction/fifaworldcup/${data[indextr].username}_7.jpg`}">ดูรูปภาพ7</vs-button>
              <vs-button class="mx-1" size="small" color="rgb(115, 103, 240)" type="filled" target :href="{url: `${url}prediction/fifaworldcup/${data[indextr].username}_8.jpg`}">ดูรูปภาพ8</vs-button>
              <vs-button class="mx-1" size="small" color="rgb(115, 103, 240)" type="filled" target :href="{url: `${url}prediction/fifaworldcup/${data[indextr].username}_9.jpg`}">ดูรูปภาพ9</vs-button>
              <vs-button class="mx-1" size="small" color="rgb(115, 103, 240)" type="filled" target :href="{url: `${url}prediction/fifaworldcup/${data[indextr].username}_10.jpg`}">ดูรูปภาพ10</vs-button>


            </vs-tr>
          </template>
        </vs-table>
        </vx-card>
              </div>
          </div>
        </div>
      </div>
    </div>
    
  
  </template>
  
<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import vSelect from 'vue-select'
  
  
import axios from '../../axios'
  
import moment from 'moment'
  
// function currencyFormatter (params) {
//   return (params.value).replace(/\d(?=(\d{3})+\.)/g, '$&,')
// }
export default {
  components: {
    vSelect,
    flatPickr
  },
  data () {
    return {
      date: moment.tz('Asia/Bangkok').format('YYYY-MM-DD'),
      events: ['onChange'],
      config: {},
      url:process.env.VUE_APP_AWS3_URL,
      searchQuery: '',
      memberData: [],
      components: '',
      bankscb: []
      
    }
  },
  methods: {
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },
    currency (amount) {
      if (amount >= 0) {
        if (typeof amount === 'number') {
          amount = String(Math.abs(amount))
        }
        return (amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      } else {
        return `${(amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
      }
  
    },
    selectdate () {
      axios
        .get('prediction/predictionimg')
        .then(response => (this.memberData = response.data))
      // this.gridApi = this.gridOptions.api
    }
  
  },
  watch: {
    date (newValue) {
      this.date = newValue
      this.selectdate()
    }
  },
  async mounted () {
    
    await axios
      .get('prediction/predictionimg')
      .then(response => (this.memberData = response.data))
      
  }
    
    
}
  
</script>
  